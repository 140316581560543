@media print {
  .printViewVisibleText {
    color: black !important;
  }

  .printViewOverflowVisible {
    overflow-y: visible !important;
  }

  .printViewHide {
    display: none !important;
  }
}

/* Hide the url and page numbers in the print view */
@page {
  size: auto; /* auto is the initial value */
  margin: 10px; /* this affects the margin in the printer settings */
}
