.App {
  text-align: center;
  height: 100vh;
}

.custom-about-table {
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 11pt;
}

.custom-about-table td,
.custom-about-table th {
  border: 1px solid #ddd;
  padding: 0 8px;
  text-align: center;
}

.custom-about-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.mini-results-table {
  border-collapse: collapse;
  min-height: 100px;
}

.mini-results-table thead {
  border-bottom: 1px solid black;
}
.mini-results-table td:first-child,
.mini-results-table th:first-child {
  border-right: 1px solid black;
}

.mini-results-table td:last-child,
.mini-results-table th:last-child {
  border-left: 1px solid black;
}

.mini-results-table td,
th {
  /*padding-left: 10px;*/
  /*padding-right: 10px;*/
  min-width: 60px;
  text-align: center;
}

.mini-results-table td {
  /*border-right: 1px solid lightgrey;*/
}

.conflict-points-table {
  border-collapse: collapse;
  border: 1px solid lightblue;
}

.conflict-points-table thead {
  border-bottom: 1px solid dodgerblue;
}

.conflict-points-table th {
  min-width: 60px;
  text-align: center;
  font-size: 14px;
  border-right: 1px solid dodgerblue;
}

.conflict-points-table th:last-child {
  border-right: 1px solid lightblue;
  padding: 0 10px 0;
  background-color: dodgerblue;
  color: white;
}

.conflict-points-table td {
  min-width: 60px;
  text-align: center;
  font-size: 14px;
  border-right: 1px solid dodgerblue;
  border-top: 1px solid dodgerblue;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(-90deg);
}

/*.custom-about-table tr:hover {background-color: #ddd;}*/
